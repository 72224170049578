//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => {
    return {
      state: true,
    }
  },
  created() {
    return this.$store
      .dispatch("users/confirmEmail", {
        confirmToken: this.$route.params.confirmToken,
      })
      .then((response) => {
        this.$store.commit("services/STORAGE_TOKEN", response.data.token);
        this.$store.commit("users/SET_CURRENT_USER", response.data.user);
        this.$store.commit("SET_AUTHENTICATION");
        this.$router.push({ name: "Profile" });
      })
      .catch(() => {
        this.state = false;
      });
  },
};
